import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, TablePagination, CircularProgress, Box, Skeleton, Tooltip, Typography, IconButton } from "@mui/material";
import { Download, Error, KeyboardArrowDown, KeyboardArrowUp, Sell, UnfoldMore, ViewAgenda, Visibility, Warning } from "@mui/icons-material";
import moment from "moment/moment";
import PropTypes from 'prop-types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    "& svg": {
        position: "relative",
        top: "5px"
    },
    '&:last-child': {
        // paddingRight: 64,
        '& svg': {
            // display: 'none',
            // color: theme.palette.primary.dark
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function CircularProgressWithLabel(props) {
    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box position="relative" display="inline-flex">
                    <CircularProgress sx={{ marginTop: "80px", minWidth: "80px", minHeight: "80px" }} variant="determinate" {...props} />

                    <Box
                        top={80}
                        left={0}
                        bottom={0}
                        right={0}
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                        >{`${Math.round(props.value)}%`}</Typography>
                    </Box>

                </Box>
                <Typography sx={{ marginTop: "20px", fontWeight: "bold" }}>
                    {props.message}
                </Typography>
            </div>
        </>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired
};

export default function AdjustedAllocationTable(props) {


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.footer ? 5 : 20);
    const [sortDirection, setSortDirection] = React.useState('asc');
    const [sortedColumn, setSortedColumn] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const handleSort = (columnId) => {
        let direction = 'asc';
        if (sortedColumn === columnId) {
            direction = sortDirection === 'asc' ? 'desc' : 'asc';
        }
        setSortDirection(direction);
        setSortedColumn(columnId);

        // Sorting logic
        let sortedData = props.rows.slice().sort((a, b) => {
            if (direction === 'asc') {
                return a[columnId] > b[columnId] ? 1 : -1;
            } else {
                return a[columnId] < b[columnId] ? 1 : -1;
            }
        });

        // Update rows
        props.setRows(sortedData);
    };

    // const [progress, setProgress] = React.useState(10);

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);


    return (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {props.columns.map((col, index) =>
                                props.sort ?
                                    ((index === (props.columns.length - 1))) ?
                                        <StyledTableCell>

                                            {col.name}

                                        </StyledTableCell>

                                        :
                                        <Tooltip title={col.tooltip}>
                                            <StyledTableCell style={{ whiteSpace: "nowrap" }} onClick={() => handleSort(col.id)}>
                                                {sortedColumn === col.id ? <span style={{ fontWeight: "700" }}>{col.name}</span> : col.name}
                                                {sortedColumn === col.id ? sortDirection === "asc" ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}
                                            </StyledTableCell>
                                        </Tooltip>
                                    :

                                    index === 0 ?
                                        <Tooltip title={col.tooltip}>
                                            <StyledTableCell style={{ whiteSpace: "nowrap" }} onClick={() => handleSort(col.id)}>
                                                {sortedColumn === col.id ? <span style={{ fontWeight: "700" }}>{col.name}</span> : col.name}
                                                {sortedColumn === col.id ? sortDirection === "asc" ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}
                                            </StyledTableCell>
                                        </Tooltip>
                                        :
                                        <Tooltip title={col.tooltip}>
                                            <StyledTableCell style={{ whiteSpace: "nowrap" }} align="left" onClick={() => handleSort(col.id)}>
                                                {sortedColumn === col.id ? <span style={{ fontWeight: "700" }}>{col.name}</span> : col.name}
                                                {sortedColumn === col.id ? sortDirection === "asc" ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" /> : <UnfoldMore fontSize="small" />}
                                            </StyledTableCell>
                                        </Tooltip>


                            )}
                        </TableRow>
                    </TableHead>
                    {(props.loadingList || props.loading) ? (
                        <TableBody sx={{ position: "relative", height: "465px", overflow: "hidden" }}>
                            {/* <Box p={3} sx={{ position: "absolute", width: "100%" }}> */}
                            <Box p={3} sx={{ position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", width: props.loading ? "70%" : "100%" }}>
                                <CircularProgress />
                                {/* <Skeleton height={60} />
                                <Skeleton height={60} />
                                <Skeleton height={60} />
                                <Skeleton height={60} />
                                <Skeleton height={60} />
                                <Skeleton height={60} />
                                <Skeleton height={60} /> */}

                            </Box>


                        </TableBody>
                    ) : (
                        <TableBody>
                            {props.rows?.length ? (
                                props.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index1) => (
                                    <StyledTableRow key={Math.random()}>
                                        {props.columns.map((col, index) => {

                                            const timeFormatMatch = /(.+?)\((.*?)\)/.exec(row.ID);

                                            return (

                                                <React.Fragment key={index}>
                                                    {col.id === "image" ? (
                                                        index === 0 ? (
                                                            <StyledTableCell component="th" scope="row">
                                                                <img
                                                                    src={row.image}
                                                                    style={{
                                                                        width: "100px",
                                                                        height: "100px",
                                                                        objectFit: "contain",
                                                                    }}
                                                                    alt={row.firstname}
                                                                />
                                                            </StyledTableCell>
                                                        ) : (
                                                            <StyledTableCell align="left">
                                                                <img
                                                                    src={row.image}
                                                                    style={{
                                                                        width: "100px",
                                                                        height: "100px",
                                                                        objectFit: "contain",
                                                                    }}
                                                                    alt={row.firstname}
                                                                />
                                                            </StyledTableCell>
                                                        )
                                                    ) : (
                                                        (col.id === "clientName" ||
                                                            col.id === "description" ||
                                                            col.id === "shortDescription" ||
                                                            col.id === "text") ? (
                                                            <StyledTableCell align="left" sx={{ width: "120px" }}>
                                                                {row[col.id]
                                                                    .substring(0, 25)
                                                                    .concat(row[col.id].length > 25 ? "..." : ' ')
                                                                    .replaceAll(/<[^>]+>/g, '')}
                                                            </StyledTableCell>
                                                        ) : col.id === "actions" ? (
                                                            <StyledTableCell align="left" sx={{ minWidth: "220px" }}>
                                                                <>
                                                                    <Button
                                                                        color="primary"
                                                                        variant="outlined"
                                                                        onClick={() => props.handleResume(row)}
                                                                    >
                                                                        Resume
                                                                    </Button>
                                                                    {props.keyLoading && props.deletingRow === row.id ? (
                                                                        <CircularProgress
                                                                            style={{
                                                                                height: "25px",
                                                                                width: "25px",
                                                                                marginLeft: "30px",
                                                                                position: "relative",
                                                                                top: "10px",
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            color="secondary"
                                                                            variant="outlined"
                                                                            onClick={() => props.handleDelete(row)}
                                                                            sx={{ marginLeft: "10px" }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    )}
                                                                </>
                                                            </StyledTableCell>
                                                        ) : (col.id === "added" || col.id === "lastModified") ? (
                                                            <StyledTableCell align="left">
                                                                {moment(row[col.id]).format("ddd, MMM Do YYYY, h:mm a")}
                                                            </StyledTableCell>
                                                        ) : (col.id === "ID") ? (
                                                            <StyledTableCell align="left">
                                                                {timeFormatMatch[1]} ({moment(timeFormatMatch[2]).format("ddd, MMM Do YYYY, h:mm a")})
                                                            </StyledTableCell>
                                                        ) : col.id === "total" ? (
                                                            <StyledTableCell align="left">
                                                                {"$" + row[col.id]}
                                                            </StyledTableCell>
                                                        ) : col.id === "Download_CSV" ? (
                                                            <StyledTableCell align="left">
                                                                {/* // row.state === "processing" ||
                                                        //   row.state ===
                                                        //   "despatch_in_progress" ? // <Skeleton variant="circular" width={40} height={40} />
                                                        //   null : */}
                                                                {row[col.id] !== null && row[col.id] !== "" ? (
                                                                    <IconButton
                                                                        href={row[col.id]}
                                                                        download
                                                                        target="_blank"
                                                                    >
                                                                        <Download color="primary" fontSize="medium" />
                                                                    </IconButton>
                                                                )
                                                                    : null}
                                                            </StyledTableCell>
                                                        ) : col.id === "View_Data" ? (
                                                            // row.state === "processing" ||
                                                            //   row.state ===
                                                            //   "despatch_in_progress" ? // <Skeleton variant="circular" width={40} height={40} />
                                                            //   null :
                                                            <StyledTableCell align="left">
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => props.getLoading(row)}
                                                                    disabled={row.Status !== "Ready to download" || index1 !== 0}
                                                                >
                                                                    View
                                                                </Button>
                                                            </StyledTableCell>
                                                        ) : col.id === "missing_stock_codes" ? (
                                                            row[col.id] && row[col.id].length > 0 && (
                                                                <StyledTableCell align="left">
                                                                    <Tooltip title={<span>
                                                                        Missing Stock Codes in Job Line and NetSuite:
                                                                        <span style={{ fontWeight: "600" }}>{row[col.id].join(", ")}</span>
                                                                    </span>}>
                                                                        <IconButton>
                                                                            <Error color="primary" fontSize="small" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </StyledTableCell>
                                                            )
                                                        ) : col.id === "sales_details" ? (
                                                            <StyledTableCell align="left">
                                                                {row[col.id].length}
                                                            </StyledTableCell>
                                                        ) : (
                                                            <StyledTableCell align="left">{row[col.id]}</StyledTableCell>
                                                        )
                                                    )}
                                                </React.Fragment>
                                            )
                                        })}
                                    </StyledTableRow>
                                ))
                            ) : (
                                <TableRow sx={{ position: "relative", height: "50px" }}>
                                    {props.footer ? <TableCell
                                        sx={{
                                            position: "absolute",
                                            left: "45%",
                                            borderBottom: "none",
                                        }}
                                    >
                                        FETCHING DATA ...
                                    </TableCell> :
                                        <TableCell
                                            sx={{
                                                position: "absolute",
                                                left: "30%",
                                                borderBottom: "none",
                                            }}
                                        >
                                            NO DATA FOUND
                                        </TableCell>
                                    }
                                </TableRow>
                            )}
                        </TableBody>
                    )}


                </Table>
            </TableContainer>
            {props.footer !== false && (
                <TablePagination
                    rowsPerPageOptions={[5, 20, 50, 75, 100]}
                    component="div"
                    count={props.rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Paper>
    );
}
