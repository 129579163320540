import {
    Box,
    Button,
    Container,
    Grid,
    Portal,
    Snackbar,
    Tooltip,
    Typography,
    styled,
} from "@mui/material";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Close, FilterList } from "@mui/icons-material";

import useApiCall from "../../../../hooks/useApiCall";
import FilterForm from "../../../../Components/FilterForm/FilterForm";

import { colDataExtendAttributes } from "../../../../utils/DataGrid/colDataExtendAttributes";

import { dateFormatter } from "../../../../utils/DataGrid/dateFormatter";
import ExcelMenu from "../../../../Components/ExcelMenu/ExcelMenu";
import MuiAlert from "@mui/material/Alert";
import useGetCall from "../../../../hooks/useGetCall";
import { httpclient } from "../../../../utils/httpClient";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const formatStockCodes = (stockCodesString) => {
    // Split the string into an array based on newline character
    const stockCodesArray = stockCodesString.split("\n");

    // Join the array elements using commas
    const formattedStockCodes = stockCodesArray.join(",");

    return formattedStockCodes;
};

const TWOrderTestingTable = ({
    customIDKey,
    columns,
    columns1,
    filterColumn,
    headerTitle,
    exportFeature,
    pageUrl,
    pageUrl1,
    exportFileLink0,
    exportFileLink,
    exportFileLink1,
    exportFileLink2,
    columnsVisibility,
    children,
    dataChanged,
    hideMenu,
    navigateState,
    forecastPassedUrl,
    tempTableOff,
    alert,
}) => {
    const [open, setOpen] = useState(false);

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [messageState, setMessageState] = useState("");

    const [regenerateLoading, setRegenerateLoading] = useState(false);
    const [soLoading, setSOLoading] = useState(false);
    const [soLoading1, setSOLoading1] = useState(false);
    const [despatchLoading, setDespatchLoading] = useState(false);
    const [regenerateError, setRegenerateError] = useState("error");
    const [regenerateMessage, setRegenerateMessage] = useState("error");


    const [toogleFilterForm, setToogleFilterForm] = useState(false);
    const [toogleChildren, setToogleChildren] = useState(false);
    const [passRowParams, setPassRowParams] = useState({});

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });
    const [paginationModel1, setPaginationModel1] = useState({
        page: 0,
        pageSize: 20,
    });

    const [sortModel, setSortModel] = useState([
        {
            field: "",
            sort: "",
        },
    ]);
    const [sortModel1, setSortModel1] = useState([
        {
            field: "",
            sort: "",
        },
    ]);

    const [filterData, setFilterData] = useState("");
    const [refetch, setRefetch] = useState(false);
    const [refetch1, setRefetch1] = useState(false);


    const { loading, error, data, pageInfo, despatchReportFlag } = useApiCall(
        `${pageUrl}?pagination=${paginationModel.pageSize}&page=${paginationModel.page + 1
        }&sort_by=${sortModel[0]?.field || ""}&direction=${sortModel[0]?.sort || ""
        }&${filterData}`,
        "GET",
        [sortModel, paginationModel, filterData, dataChanged, refetch === true]
        //check this dependencies pagination working only after sorting model changed
    );

    useEffect(() => {
        setRefetch(false);
    }, [data])

    const { loading: loading1, error: error1, data: data1, pageInfo: pageInfo1 } = useApiCall(
        `${pageUrl1}?pagination=${paginationModel1.pageSize}&page=${paginationModel1.page + 1
        }&sort_by=${sortModel1[0]?.field || ""}&direction=${sortModel1[0]?.sort || ""
        }&${filterData}`,
        "GET",
        [sortModel1, paginationModel1, filterData, dataChanged, refetch1 === true]
        //check this dependencies pagination working only after sorting model changed
    );

    useEffect(() => {
        setRefetch1(false);
    }, [data1])

    const defaultHistoricalSalesStart = "2022-05-01";
    const defaultHistoricalSalesEnd = "2023-05-30";
    const defaultSupplierLeadTimeInWeeks = 4;
    const defaultDefaultDemand = "HIST";
    const defaultStockCodes = "";

    let apiCallUrl;

    if (navigateState?.filterData) {
        const {
            supplierLeadTime,
            custLeadTimeDays,
            stock_codes,
            customerID,
            coverageBuffer,
            cycleFrequency,
            defaultDemand,
            defaultForecast,
            smoothing,
            recentSalesWeeks,
            useSalesDataSince,
            historicalSalesStart,
            salesExcludeMonths,
            historicalSalesEnd,
            globalAllocRamping,
            globalSales,
            meanTrimming,
            otherSettings,
            currentProductOnly,
            dataRetrieveOnly,
            ignoreSupplier,
            standardAllocations,
            adjustedAllocations,
            allocateToMSLOnly,
        } = navigateState?.filterData;
        let defaultDemandDependentParameters;

        if (defaultDemand === "RECENT") {
            defaultDemandDependentParameters = `?recentSalesWeek=${recentSalesWeeks}`;
        }
        if (defaultDemand === "HIST") {
            defaultDemandDependentParameters = `?historicalSalesStart=${historicalSalesStart}&historicalSalesEnd=${historicalSalesEnd}`;
        } else {
            defaultDemandDependentParameters = `?`;
            // `?salesExcludeMonths=${salesExcludeMonths}`;
        }
        apiCallUrl = `${forecastPassedUrl}${defaultDemandDependentParameters}&supplierLeadTime=${supplierLeadTime}&defaultDemand=${defaultDemand}&stock_codes=${formatStockCodes(
            stock_codes
        )}`;
    } else {
        apiCallUrl = `${forecastPassedUrl}?historicalSalesStart=${defaultHistoricalSalesStart}&historicalSalesEnd=${defaultHistoricalSalesEnd}&supplierLeadTime=${defaultSupplierLeadTimeInWeeks}&defaultDemand=${defaultDefaultDemand}`;
    }

    useEffect(() => {
        console.log("NAVIGATE FILTER DATA", navigateState?.filterData);
    }, [navigateState]);

    const {
        loading: downloadForecastLoading,
        error: downloadForecastError,
        data: downloadForecastData,
        message: downloadForecastMessage,
    } = useGetCall(apiCallUrl, forecastPassedUrl, [navigateState]);

    const handleRegenerate = () => {
        setRegenerateLoading(true);
        httpclient
            .get(exportFileLink)
            .then(({ data }) => {
                if (data.success) {
                    setRegenerateLoading(false);
                    setTimeout(() => {
                        setRefetch1(true);
                    }, 1000);
                    setSeverity("success");
                    setRegenerateMessage(data.message);
                    setOpen(true);
                }
            })
            .catch((err) => {
                setRegenerateLoading(false);
                setSeverity("error");
                setRegenerateError("An error occurred while fetching data.");
                setOpen(true);
            });
    };

    const handleSORegenerate = () => {
        setSOLoading(true);
        httpclient
            .get(exportFileLink1)
            .then(({ data }) => {
                if (data.success) {
                    setSOLoading(false);
                    setSeverity("success");
                    setRegenerateMessage(data.message);
                    setOpen(true);
                }
            })
            .catch((err) => {
                setSeverity("error");
                setSOLoading(false);
                setRegenerateError("An error occurred while fetching data.");
                setOpen(true);
            });
    };

    const handleSOImport = () => {
        setSOLoading1(true);
        httpclient
            .get(exportFileLink2)
            .then(({ data }) => {
                if (data.success) {
                    setSOLoading1(false);
                    setSeverity("success");
                    setRegenerateMessage(data.message);
                    setOpen(true);
                }
            })
            .catch((err) => {
                setSeverity("error");
                setSOLoading1(false);
                setRegenerateError("An error occurred while fetching data.");
                setOpen(true);
            });
    };

    const handleDespatch = () => {
        setDespatchLoading(true);
        httpclient
            .get(exportFileLink0)
            .then(({ data }) => {
                if (data.success) {
                    setDespatchLoading(false);
                    setTimeout(() => {
                        setRefetch(true);
                    }, 1000);
                    setSeverity("success");
                    setRegenerateMessage(data.message);
                    setOpen(true);
                }
            })
            .catch((err) => {
                setSeverity("error");
                setDespatchLoading(false);
                setRegenerateError("An error occurred while fetching data.");
                setOpen(true);
            });
    };


    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenAlert(false);
    };

    useEffect(() => {
        if (alert === true) {
            AlertLink();
            const intervalId = setInterval(AlertLink, 5 * 1000);
            return () => clearInterval(intervalId);
        }
    }, []);


    const AlertLink = () => {

        httpclient
            .get(`temple-webster/soh-regenerate-alert-message`)
            .then(({ data }) => {
                if (data.success) {
                    setOpenAlert(true);
                    setMessage(data.message);
                    setMessageState("success")

                } else {
                    setOpenAlert(true);
                    setMessage(data.message);
                    setMessageState("error")

                }
            })

    };

    const [rowCountState, setRowCountState] = React.useState(
        pageInfo?.total || 0
    );
    const [rowCountState1, setRowCountState1] = React.useState(
        pageInfo1?.total || 0
    );

    const handleFilterDataChange = (newFilterData) => {
        setFilterData(newFilterData);
    };

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            pageInfo?.total !== undefined ? pageInfo?.total : prevRowCountState
        );
    }, [pageInfo?.total, setRowCountState]);

    useEffect(() => {
        setRowCountState1((prevRowCountState1) =>
            pageInfo1?.total !== undefined ? pageInfo1?.total : prevRowCountState1
        );
    }, [pageInfo1?.total, setRowCountState1]);

    const handleSortModelChange = (model) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
        }
    };

    const handleSortModelChange1 = (model) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel1)) {
            setSortModel1(model);
        }
    };
    const handleCellClick = (param, event) => {
        event.stopPropagation();
    };

    const customToolBar = () => {
        return (
            <>
                {/* {exportFeature && (
            <Portal container={() => document.getElementById("filter-panel")}>
              <GridToolbarExport
                csvOptions={{
                  fileName: headerTitle + String(dateFormatter(Date.now())),
                }}
                type="Button"
                color="primary"
                variant="contained"
                sx={{ padding: "6.5px 16px" }}
              />
            </Portal>
          )} */}
            </>
        );
    };

    const Reddiv = styled("div")(({ theme }) => ({
        background: messageState === "success" ? "#4caf50" : "#d32f2f",
        color: "#fff",
        textAlign: "center",
        padding: "10px",
        marginRight: "10px",
        position: "relative",
        borderRadius: "5px",
        "& span": {
            color: "#ffffff",
            fontFamily: "Poppins",
        },

        "& svg": {
            position: "absolute",
            right: "10px",
            fontSize: "20px",
            cursor: "pointer",
        },
    }));

    return (
        <>
            {/* maxWidth={false}> */}
            {!error && (
                <>
                    <Grid container spacing={2} sx={{ width: "100%" }}>
                        <Grid item md={4} xs={12}>
                            <h1>{headerTitle}</h1>
                            <Typography fontWeight={"bold"}>
                                {navigateState?.fileName
                                    ? `File Name: ${navigateState?.fileName}`
                                    : ""}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            md={8}
                            xs={12}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            {openAlert &&
                                <Reddiv>
                                    <span>{message} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <Close onClick={handleClose} />
                                </Reddiv>
                            }


                            {exportFeature && (
                                <Box id="filter-panel">
                                    {exportFileLink0 && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={despatchLoading || (despatchReportFlag === 0)}
                                            sx={{ mr: 2 }}
                                            onClick={handleDespatch}
                                        >
                                            {despatchLoading
                                                ? "Generating..."
                                                : "Generate Despatch Report"}
                                        </Button>
                                    )}
                                </Box>
                            )}


                            {exportFeature && (
                                <Box id="filter-panel">
                                    {exportFileLink1 && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            // disabled={soLoading}
                                            disabled
                                            sx={{ mr: 2 }}
                                            onClick={handleSORegenerate}
                                        >
                                            {soLoading
                                                ? "Pulling ..."
                                                : "1. PULL FTP ACR SO FILE"}
                                        </Button>
                                    )}
                                </Box>
                            )}

                            {exportFeature && (
                                <Box id="filter-panel">
                                    {exportFileLink2 && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            // disabled={soLoading1}
                                            disabled
                                            sx={{ mr: 2 }}
                                            onClick={handleSOImport}
                                        >
                                            {soLoading1
                                                ? "Reformating ..."
                                                : "2. REFORMAT SO FILES FOR TW"}
                                        </Button>
                                    )}
                                </Box>
                            )}

                            {exportFeature && (
                                <Box id="filter-panel">
                                    {exportFileLink && (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            // disabled={regenerateLoading}
                                            disabled
                                            sx={{ mr: 2 }}
                                            onClick={handleRegenerate}
                                        >
                                            {regenerateLoading
                                                ? "Generating ..."
                                                : "3. GENERATE TRACKING FILE  FOR TW"}
                                        </Button>
                                    )}
                                </Box>
                            )}
                            {!!filterColumn && (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setToogleFilterForm((prev) => !prev)}
                                    style={{ marginLeft: "5px" }}
                                >
                                    Filter
                                    <FilterList fontSize="small" />
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {!!filterColumn && (
                                <FilterForm
                                    toogle={toogleFilterForm}
                                    navigateState={navigateState}
                                    filterColumn={filterColumn}
                                    onFilterDataChange={handleFilterDataChange}
                                />
                            )}

                            {navigateState?.startDate && (
                                <ExcelMenu hideMenu={hideMenu} navigateState={navigateState} />
                            )}
                        </Grid>
                        <Grid container spacing={0} sx={{ width: "100%" }}>
                            <Grid item xs={8}>
                                <Box sx={{ background: "#ffffff" }} pl={2} pt={2} pb={2}><b>Despatch Report</b></Box>

                                {!tempTableOff && (
                                    <DataGrid
                                        //this optional
                                        slots={{
                                            toolbar: customToolBar,
                                        }}
                                        //this changes and optional
                                        columnVisibilityModel={columnsVisibility}
                                        //this changes and mandatory
                                        getRowId={
                                            customIDKey ? (row) => row[customIDKey] : () => uuidv4()
                                        }
                                        rows={data}
                                        onRowClick={
                                            children
                                                ? (params) => {
                                                    setToogleChildren((prev) => !prev);
                                                    setPassRowParams(params);
                                                    // console.log("ROW CLICKED", params.row);
                                                }
                                                : null
                                        }
                                        rowCount={rowCountState}
                                        columns={colDataExtendAttributes(columns)}
                                        loading={loading || regenerateLoading}
                                        disableColumnMenu
                                        autoHeight={true}
                                        pageSizeOptions={[20, 50, 70, 100]}
                                        paginationMode="server"
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={setPaginationModel}
                                        onCellClick={handleCellClick}
                                        sortingMode="server"
                                        sortModel={sortModel}
                                        onSortModelChange={handleSortModelChange}
                                    />
                                )}
                            </Grid>

                            <Grid item xs={4}>
                                <Box sx={{ background: "#ffffff" }} pl={2} pt={2} pb={2}><b>Tracking File</b></Box>

                                {!tempTableOff && (
                                    <DataGrid
                                        //this optional
                                        slots={{
                                            toolbar: customToolBar,
                                        }}
                                        //this changes and optional
                                        columnVisibilityModel={columnsVisibility}
                                        //this changes and mandatory
                                        getRowId={
                                            customIDKey ? (row) => row[customIDKey] : () => uuidv4()
                                        }
                                        rows={data1}
                                        onRowClick={
                                            children
                                                ? (params) => {
                                                    setToogleChildren((prev) => !prev);
                                                    setPassRowParams(params);
                                                    // console.log("ROW CLICKED", params.row);
                                                }
                                                : null
                                        }
                                        rowCount={rowCountState1}
                                        columns={colDataExtendAttributes(columns1)}
                                        loading={loading1 || regenerateLoading}
                                        disableColumnMenu
                                        autoHeight={true}
                                        pageSizeOptions={[20, 50, 70, 100]}
                                        paginationMode="server"
                                        paginationModel={paginationModel1}
                                        onPaginationModelChange={setPaginationModel1}
                                        onCellClick={handleCellClick}
                                        sortingMode="server"
                                        sortModel={sortModel1}
                                        onSortModelChange={handleSortModelChange1}
                                    />
                                )}
                            </Grid>

                        </Grid>

                        {toogleChildren &&
                            React.cloneElement(children, {
                                setToogleChildren: setToogleChildren,
                                parameter: passRowParams,
                            })}
                    </Grid>

                    <Snackbar
                        open={open}
                        autoHideDuration={3000}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        onClose={(event, reason) => {
                            if (reason === "clickaway") {
                                return;
                            }
                            setOpen(false);
                        }}
                    >
                        {/* Display message with appropriate severity */}
                        <Alert severity={severity} onClose={() => setOpen(false)}>
                            {regenerateMessage}
                        </Alert>
                    </Snackbar>;

                    {/* <Snackbar
              //autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={openAlert}
              onClose={handleClose}
              style={{
                position: "fixed",
                top: "20%",
                left: "50%",
                width:"500px",
                //transform: "translate(-50%, -50%)",
                zIndex: 1000, // Set your desired z-index value
              }}
            >
              <Alert
                onClose={handleClose}
                severity={messageState}
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar> */}
                </>
            )}
            {error && console.log("error message", error)}
        </>
    );
};

export default TWOrderTestingTable;
