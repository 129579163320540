import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CircularProgress,
  Collapse,
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import FormTextField from "./components/FormTextField";
import FormCheckbox from "./components/FormCheckbox";
import FormAutocomplete from "./components/FormAutocomplete";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PATH from "../../../Routing/PATHS";
import { httpclient } from "../../../utils/httpClient";
import { ExpandMore } from "@mui/icons-material";
import LoadingScreen from "../../../Components/LoadingScreen";
import MuiAlert from "@mui/material/Alert";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function convertCommaSeparatedToNewline(inputString) {
  if (!inputString) {
    return "";
  }
  const dataArray = inputString.split(",").filter(Boolean); // Filter out empty values
  return dataArray.join("\n");
}

const ReplenishmentForecast = ({ }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = {
    defaultDemand: [
      { id: 1, name: "MAX" },
      { id: 2, name: "MAXCAT" },
      { id: 3, name: "NORMAL" },
      { id: 4, name: "NORMALCAT" },
      { id: 5, name: "RECENT" },
      { id: 6, name: "HIST" },
    ],
    defaultForecast: [
      { id: 1, name: "EOQ" },
      { id: 2, name: "JIT" },
    ],
    smoothing: [
      { id: 1, name: "NONE" },
      { id: 2, name: "LIGHT" },
      { id: 3, name: "HEAVY" },
    ],
    startOfTheWeek: [
      { id: 1, name: "SUNDAY" },
      { id: 2, name: "MONDAY" },
    ],
    // excludeMonth: [
    //   { id: 1, name: "11" },
    //   { id: 2, name: "12" },
    // ],

  };

  const [showField, setShowField] = useState(false);

  const [filterData, setFilterData] = useState({
    allocationType: 'adjusted',
    supplierLeadTime: location.state ? location.state.filterData.supplierLeadTime : "6",
    custLeadTimeDays: location.state ? location.state.filterData.custLeadTimeDays : "14",
    stockCodes: "",
    customerID: "",
    coverageBuffer: location.state ? location.state.filterData.coverageBuffer : "0",
    cycleFrequency: location.state ? location.state.filterData.cycleFrequency : "1",
    defaultDemand: location.state ? location.state.filterData.defaultDemand : "NORMAL",
    defaultForecast: location.state ? location.state.filterData.defaultForecast : "JIT",
    smoothing: location.state ? location.state.filterData.smoothing : "LIGHT",
    recentSalesWeek: "4",
    useSalesDataSince: location.state ? location.state.filterData.useSalesDataSince : "365",
    historicalSalesStart: "2022-07-01",
    salesExcludeMonths: location.state ? location.state.filterData.salesExcludeMonths : "11,12",
    historicalSalesEnd: "2022-09-30",
    globalAllocRamping: location.state ? location.state.filterData.globalAllocRamping : "0",
    globalSales: location.state ? location.state.filterData.globalSales : "0.75",
    meanTrimming: location.state ? location.state.filterData.meanTrimming : "0",
    otherSettings: location.state ? location.state.filterData.otherSettings : "",
    // importedDate: "",
    startOfTheWeek: "",
    currentProductOnly: false,
    dataRetrieveOnly: false,
    ignoreSupplier: false,
    standardAllocations: false,
    adjustedAllocations: true,
    allocateToMSLOnly: false,
  });

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [mappedLoading, setMappedLoading] = useState(false);
  /*Imported Date*/
  const [importedDateLoading, setImportedDateLoading] = useState(false);
  const [importedDateData, setImportedDateData] = useState([]);
  const [notFound, setNotFound] = useState([]);

  useEffect(() => {
    httpclient
      .get(`replenishment/stockCodes/getstockCodes`)
      .then(({ data }) => {
        if (data.success) {
          setLoading(false);
          setFilterData((prevFilterData) => ({
            ...prevFilterData,
            stockCodes: `${convertCommaSeparatedToNewline(
              data.data.stock_codes
            )}`,
          }));

          if (!!data.data.stock_codes === true) {
            setExpanded("panel");
          } else if (!!data.data?.stock_codes === false) {
            setExpanded("panel1");
          }
        } else {
          setLoading(false);
        }
      });
    //setImportedDateLoading(true);
    // httpclient.get(`replenishment/getStoreSohDateRange`).then(({ data }) => {
    //   if (data.success) {
    //     setImportedDateLoading(false);
    //     const dataModified = data.data.map((item, index) => ({
    //       id: index,
    //       name: `${item.updatedDate}: ${item.tempSessionId}`,
    //     }));
    //     setImportedDateData(dataModified);
    //     setFilterData((prevFilterData) => ({
    //       ...prevFilterData,
    //       importedDate: dataModified[0].name,
    //     }));
    //   } else {
    //     setImportedDateLoading(false);
    //   }
    // });
  }, []);

  useEffect(() => {
    handleMappedStockCodes();
    const intervalId = setInterval(handleMappedStockCodes, 5 * 60 * 1000); // Fetch data every 60 seconds
    return () => clearInterval(intervalId);
  }, []);

  const handleMappedStockCodes = () => {
    setMappedLoading(true);
    httpclient
      .get(`https://ubatemp.retailcare.com.au/php/mapped-stock-codes`)
      .then(({ data }) => {
        if (data.success) {
          setMappedLoading(false);
          if (data.not_found_stock_codes && data.not_found_stock_codes.length > 0) {
            setOpen(true);
            setMessageState("warning");
            setMessage(
              <span>
                These stock codes are not available in Job Line and NetSuite: 
                <span style={{ fontWeight: "600" }}>{data.not_found_stock_codes.join(", ")}</span>
              </span>
            );
            
          }
        }
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleChangeFilter = (e) => {
    const { name, value, checked, type } = e.target;


    // Use a conditional to check the type of the input
    const inputValue = type === "checkbox" ? checked : value;

    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: inputValue,
    }));
  };

  const handleChangeFilterRadio = (event) => {
    const { name, value } = event.target;

    if (name === 'allocationType') {
      const standardAllocations = value === 'standard';
      setFilterData({
        ...filterData,
        allocationType: value,
        standardAllocations,
        adjustedAllocations: !standardAllocations,
        globalSales: standardAllocations ? "0" : "0.75"
      });
    } else {
      setFilterData({
        ...filterData,
        [name]: value
      });
    }
  };



  // const handleChangeFilter = (e) => {
  //   const { name, value, checked, type } = e.target;

  //   // Use a conditional to check the type of the input
  //   let inputValue;
  //   if (name === "salesExcludeMonths") {
  //     // If the field is salesExcludeMonths and the type is text, split the comma-separated string into an array
  //     inputValue = type === "text" ? value.split(',') : [];
  //   } else {
  //     inputValue = type === "checkbox" ? checked : value;
  //   }

  //   setFilterData((prevFilterData) => ({
  //     ...prevFilterData,
  //     [name]: inputValue,
  //   }));
  // };


  const handleChangeAuto = (name, value, getOptionLabelFromAutocomplete) => {
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      [name]: value !== null ? value[getOptionLabelFromAutocomplete] : "",
    }));
    if (name === "defaultDemand") {
      setShowField(false);
      setTimeout(() => {
        setShowField(true);
      }, 400);
    }
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {loading || importedDateLoading ? (
        <LoadingScreen message="Retrieving stock codes data..." />
      ) : (
        <>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel-1"
            >
              <Typography variant="h5">Enter Stock Codes</Typography>
              {mappedLoading && (
                <>
                  <CircularProgress
                    style={{
                      height: "25px",
                      width: "25px",
                      marginLeft: "20px",
                      position: "relative",
                    }}
                  />
                  <Typography variant="h6">Fetching New Stocks..</Typography>
                </>
              )}


            </AccordionSummary>
            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <FormTextField
                    label="Enter stock code to generate forecasting (1 stock code per line)"
                    name="stockCodes"
                    multiline={true}
                    rows={4}
                    value={filterData.stockCodes}
                    onChange={handleChangeFilter}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h5">List Main Parameter</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container maxWidth="md">
                <Paper elevation={20}>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: 3 }}
                    maxWidth="md"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {/* <Grid item xs={12}>
                  <h1 style={{ margin: 0, padding: 0 }}>
                    List Main Parameters
                  </h1>
                </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Supplier Lead Time (in Weeks)"
                        type="number"
                        name="supplierLeadTime"
                        value={filterData.supplierLeadTime}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Cust Lead Time Days"
                        type="number"
                        name="custLeadTimeDays"
                        value={filterData.custLeadTimeDays}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
              <FormTextField
                label="Customer ID"
                type="text"
                name="customerID"
                value={filterData.customerID}
                onChange={handleChangeFilter}
                defaultValue={"TCS1"}
              />
            </Grid> */}
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Coverage Buffer"
                        type="number"
                        name="coverageBuffer"
                        value={filterData.coverageBuffer}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Cycle Frequency"
                        type="number"
                        name="cycleFrequency"
                        value={filterData.cycleFrequency}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="defaultDemand"
                        label="Default Demand"
                        options={data.defaultDemand}
                        getOptionLabel={"name"}
                        value={filterData.defaultDemand}
                        onChange={handleChangeAuto}
                        defaultValue={data.defaultDemand[2]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="defaultForecast"
                        label="Default"
                        options={data.defaultForecast}
                        getOptionLabel={"name"}
                        value={filterData.defaultForecast}
                        onChange={handleChangeAuto}
                        defaultValue={data.defaultForecast[1]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="smoothing"
                        label="Smoothing"
                        options={data.smoothing}
                        getOptionLabel={"name"}
                        value={filterData.smoothing}
                        onChange={handleChangeAuto}
                        defaultValue={data.smoothing[1]}
                      />
                    </Grid>
                    {filterData.defaultDemand === "RECENT" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Recent Sales Weeks"
                          type="number"
                          name="recentSalesWeek"
                          value={filterData.recentSalesWeek}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        />
                      </Grid>
                    )}
                    {filterData.defaultDemand === "HIST" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Historical Sales Start"
                          type="date"
                          name="historicalSalesStart"
                          value={filterData.historicalSalesStart}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        />
                      </Grid>
                    )}
                    {(filterData.defaultDemand === "" ||
                      filterData.defaultDemand === "NORMAL" ||
                      filterData.defaultDemand === "MAX" ||
                      filterData.defaultDemand === "MAXCAT" ||
                      filterData.defaultDemand === "NORMALCAT") && (
                        <Grid item xs={12} sm={4}>
                          {/* <FormTextField
                          label="Sales Exclude Months"
                          type="text"
                          name="salesExcludeMonths"
                          //value={filterData.salesExcludeMonths}
                          value={filterData.salesExcludeMonths.join(',')}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        /> */}
                          <FormTextField
                            label="Sales Exclude Months"
                            type="text"
                            name="salesExcludeMonths"
                            value={filterData.salesExcludeMonths} // Convert array of objects to comma-separated string
                            onChange={handleChangeFilter}
                            sx={{
                              transition: "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                              backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                            }}
                          />


                        </Grid>
                      )}
                    {filterData.defaultDemand === "HIST" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Historical Sales End"
                          type="date"
                          name="historicalSalesEnd"
                          value={filterData.historicalSalesEnd}
                          onChange={handleChangeFilter}
                          sx={{
                            transition:
                              "opacity 0.5s ease , background-color 0.5s ease, color 0.5s ease", // Transitions for a smoother effect
                            backgroundColor: showField ? "#D1EBDE" : "", // Apply styles dynamically
                          }}
                        />
                      </Grid>
                    )}
                    {filterData.defaultDemand !== "HIST" && (
                      <Grid item xs={12} sm={4}>
                        <FormTextField
                          label="Use Sales Data Since (in Days)"
                          type="number"
                          name="useSalesDataSince"
                          value={filterData.useSalesDataSince}
                          onChange={handleChangeFilter}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Global Alloc Ramping"
                        type="number"
                        name="globalAllocRamping"
                        //disabled={true}
                        value={filterData.globalAllocRamping}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Global Sales"
                        type="number"
                        name="globalSales"
                        disabled={filterData.standardAllocations}
                        value={filterData.globalSales}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Mean Trimming"
                        type="number"
                        name="meanTrimming"
                        value={filterData.meanTrimming}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="startOfTheWeek"
                        label="Start Of The Week"
                        options={data.startOfTheWeek}
                        getOptionLabel={"name"}
                        value={filterData.startOfTheWeek}
                        onChange={handleChangeAuto}
                        defaultValue={data.startOfTheWeek[0]}
                      />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={4}>
                      <FormAutocomplete
                        name="importedDate"
                        label="Imported Date"
                        options={importedDateData}
                        getOptionLabel={"name"}
                        value={filterData.importedDate}
                        onChange={handleChangeAuto}
                        defaultValue={importedDateData[0]}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                      <FormTextField
                        label="Other Settings"
                        type="text"
                        name="otherSettings"
                        value={filterData.otherSettings}
                        onChange={handleChangeFilter}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        sx={{ marginTop: { sm: 3, xs: 0 } }}
                        onClick={() =>
                          setFilterData({ ...filterData, otherSettings: "" })
                        }
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>{" "}
                    <Grid item xs={12} sm={4}></Grid>{" "}
                    {/* {filterData.defaultDemand === "" && (
                      <>
                        <Grid item xs={12} sm={4}></Grid>
                      </>
                    )}

                    {(filterData.defaultDemand === "NORMAL" ||
                      filterData.defaultDemand === "NORMALCAT" ||
                      filterData.defaultDemand === "MAX" ||
                      filterData.defaultDemand === "MAXCAT" ||
                      filterData.defaultDemand === "RECENT") && (
                      <Grid item xs={12} sm={4}></Grid>
                    )} */}
                    {/* <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Current Product Only"
                        name="currentProductOnly"
                        checked={filterData.currentProductOnly}
                        onChange={handleChangeFilter}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                      <RadioGroup
                        aria-label="allocationType"
                        name="allocationType"
                        value={filterData.allocationType}
                        onChange={handleChangeFilterRadio}
                      >
                        <FormControlLabel
                          value="standard"
                          control={<Radio />}
                          label="Standard Allocations"
                        />
                        <FormControlLabel
                          value="adjusted"
                          control={<Radio />}
                          label="Adjusted Allocations"
                        />
                      </RadioGroup>
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Data Retrieve Only"
                        checked={filterData.dataRetrieveOnly}
                        onChange={handleChangeFilter}
                        name="dataRetrieveOnly"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Ignore Supplier"
                        checked={filterData.ignoreSupplier}
                        onChange={handleChangeFilter}
                        name="ignoreSupplier"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormCheckbox
                        label="Allocate to MSL Only"
                        checked={filterData.allocateToMSLOnly}
                        onChange={handleChangeFilter}
                        name="allocateToMSLOnly"
                      />
                    </Grid> */}
                  </Grid>

                  <Grid container spacing={2} sx={{ padding: 3 }}>
                    <Grid item xs={12} textAlign="right">
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          padding: 2,
                          marginTop: { xs: 1 },
                          paddingX: 4,
                          width: { xs: "100%", lg: "auto" },
                        }}
                      >
                        Clear Allocation
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          padding: 2,
                          marginTop: { xs: 1 },
                          paddingX: 4,
                          marginLeft: { xs: 0, lg: 4 },
                          width: { xs: "100%", lg: "auto" },
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        size="large"
                        disabled={mappedLoading}
                        sx={{
                          padding: 2,
                          marginTop: { xs: 1 },
                          paddingX: 4,
                          marginLeft: { xs: 0, lg: 4 },
                          width: { xs: "100%", lg: "auto" },
                        }}
                        onClick={(event) => {
                          localStorage.setItem("passAPI", true);
                          if (filterData.adjustedAllocations)
                            navigate(PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED, {
                              state: {
                                filterData: filterData,
                                from: "adjusted"
                              },
                              target: "_blank",
                            });
                          <Link
                            to={{
                              pathname: PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED,
                              state: {
                                filterData: filterData,
                              },
                            }}
                            target="_blank"
                          />;
                          if (filterData.standardAllocations)
                            navigate(PATH.REPLENISHMENT_ALLOCATIONS_ADJUSTED, {
                              state: {
                                filterData: filterData,
                              },
                            });
                        }}
                      >
                        Run Allocation
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Container>
            </AccordionDetails>
          </Accordion>
          <Snackbar
            //autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            sx={{ marginTop: "65px" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={messageState}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  );
};

export default ReplenishmentForecast;
