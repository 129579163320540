const PATHS = {
  DASHBOARD: "/",
  LOGIN: "/login",
  //PRIVATE ROUTES
  SETTINGS_SYNC: "/settings/sync",
  SETTINGS_3PL: "/settings/3pl",
  SETTINGS_HOLIDAY: "/settings/holiday_calender",
  NETSUITE_PRICE_SETTINGS: "/settings/netsuite_price_settings",
  MODULES_VIEW: "/modules_view",

  // REPLENISHMENT >> EXCLE FILE
  REPLENISHMENT_EXCEL_FILE: "/replenishment/excel_file",

  // REPLENISHMENT >> HISTORY
  REPLENISHMENT_HISTORY_SOH: "/replenishment/history/soh",

  // REPLENISHMENT >> PIPELINE
  REPLENISHMENT_PIPELINE: "/replenishment/pipeline",

  // REPLENISHMENT >> STORE SOH
  REPLENISHMENT_STORESOH: "/replenishment/store_soh",

  // REPLENISHMENT >> STORE SOH
  REPLENISHMENT_STORESALES: "/replenishment/store_sales",

  // REPLENISHMENT >> MSL LEVEL
  REPLENISHMENT_MSLLEVEL: "/replenishment/msl_level",

  // REPLENISHMENT >> QUICK REF
  REPLENISHMENT_QUICKREF: "/replenishment/quick_ref",

  // REPLENISHMENT >> SALESOVERRIDE
  REPLENISHMENT_SALESOVERRIDE: "/replenishment/sales_override",

  // REPLENISHMENT >> STOCK SUBSTITUTE
  REPLENISHMENT_STOCKSUBSTITUTE: "/replenishment/stock_substitute",

  // REPLENISHMENT >>STORE STOCK MASTER
  REPLENISHMENT_STORESTOCKMASTER: "/replenishment/store_stock_master",

  // REPLENISHMENT >> STOCK SUBSTITUTE
  REPLENISHMENT_FORECAST: "/replenishment/forecast",

  // REPLENISHMENT >> ALLOCATIONS
  REPLENISHMENT_ALLOCATIONS_ADJUSTED: "/replenishment/allocations/adjusted",
  REPLENISHMENT_ALLOCATIONS_STANDARD: "/replenishment/allocations/standard",

  // ONLINE >> CURRENT
  ONLINE_CURRENT_STOCK: "/stock/current_stock",
  ONLINE_CURRENT_BRANCH: "/stock/current_branch",
  ONLINE_CURRENT_SUPPLIER: "/supplier/current_supplier",
  ONLINE_CURRENT_STOCKGROUP: "/groups/current_stock_groups",
  ONLINE_CURRENT_POLICY: "/current/price_policy",

  //ONLINE >>NEW SYSTEM
  ONLINE_NEW_STOCK: "/stock/new_system_stock",
  ONLINE_NEW_BRANCH: "/stock/new_system_branch",
  ONLINE_NEW_BRAND: "/stock/new_system_brand",
  ONLINE_NEW_SUPPLIER: "/supplier/new_system_supplier",
  ONLINE_NEW_STOCKGROUP: "/groups/new_system_stock_groups",
  ONLINE_NEW_PRICE: "/new-system/price_policy",

  //ONLINE >> ORDERS
  ONLINE_ORDERS_ALL: "/orders/list_orders",
  ONLINE_ORDERS_EXPORT: "/orders/csv_export",

  //ONLINE >> EXCEPTION_REPORT
  ONLINE_EXCEPTION_PRICEORDER: "/orders/list_orders_price_exception",
  ONLINE_EXCEPTION_ZERO: "/stock/exceptions/zero_price",
  ONLINE_EXCEPTION_LONGDESCRIPTION: "/stock/exceptions/title_exception",
  ONLINE_EXCEPTION_CATEGORY: "/stock/exceptions/category_exception",
  ONLINE_EXCEPTION_IMAGE: "/stock/exceptions/image_missing",
  ONLINE_EXCEPTION_SYNC: "/stock/exceptions/sync_status",

  //ONLINE >> SHIPPING CLASS tab is just filtered NEW STOCK tab

  //ONLINE >> ACCOUNTS
  ONLINE_ACCOUNT_CURRENT: "/account/current_customers",

  //ONLINE >> TEMPLE AND WEBSTER
  ONLINE_TEMPLE_SOH: "/temple/soh",
  ONLINE_TEMPLE_ORDER_FILE: "/netsuite/visibility/order_file",
  ONLINE_TEMPLE_ACR_SO_FILE: "/netsuite/visibility/acr_so_file",
  ONLINE_TEMPLE_UPLOADSHIPPINGLABEL: "/temple/upload_shipping_label",
  ONLINE_TEMPLE_UPLOADORDER: "/temple/upload_order",
  ONLINE_TEMPLE_ORDERBYBRANCH: "/temple/order_by_branch",
  ONLINE_TW_ORDERS_TESTING: "/temple/order_testing",
  ONLINE_TW_NETSUITE_CONTAINER: "/netsuite/visibility/netsuite_container",
 

  ONLINE_RESYNCSKU: "/sync/resync_from_ax",
  ONLINE_SHIPPIT: "/shippit/shippit_testing",

  //ONLINE >> USERS
  ONLINE_USERS_LIST: "/users",
  ONLINE_USERS_ADD: "/users/add_user",
  ONLINE_USERS_ADDBYID: "/users/add_user/:id",
  ONLINE_USERS_LISTMODULES: "/modules",
  ONLINE_USERS_ADDMODULE: "/modules/add_module",
  ONLINE_USERS_ADDMODULEBYID: "/modules/add_module/:id",

  // NETSUITE >> CURRENT
  NETSUITE_CURRENT_STOCK: "/netsuite/stock/current_stock",
  NETSUITE_CURRENT_BRANCH: "/netsuite/stock/current_branch",
  NETSUITE_CURRENT_SUPPLIER: "/netsuite/supplier/current_supplier",
  NETSUITE_CURRENT_STOCKGROUP: "/netsuite/groups/current_stock_groups",
  NETSUITE_CURRENT_POLICY: "/netsuite/current/price_policy",

  //NETSUITE >>NEW SYSTEM
  NETSUITE_NEW_STOCK: "/netsuite/stock/new_system_stock",
  NETSUITE_NEW_BRANCH: "/netsuite/stock/new_system_branch",
  NETSUITE_NEW_SUPPLIER: "/netsuite/supplier/new_system_supplier",
  NETSUITE_NEW_STOCKGROUP: "/netsuite/groups/new_system_stock_groups",
  NETSUITE_NEW_PRICE: "/netsuite/new-system/price_policy",

  //NETSUITE >> ORDERS
  NETSUITE_ORDERS_ALL: "/netsuite/orders/list_orders",
  NETSUITE_ORDERS_EXPORT: "/netsuite/orders/csv_export",

  NETSUITE_MARKETPLACE_ORDER: "/netsuite/visibility/marketplace",
  NETSUITE_WORKFLOW_MARKETPLACE_ORDER: "/netsuite/workflow/marketplace",
  NETSUITE_VISIBILITY_TW_ORDER:"/netsuite/visibility/Temple&Wester/Orders",
  // NETSUITE_VISIBILITY_CSV_EXPORT:"/netsuite/visibility/Temple&Wester/CSVExport",
  NETSUITE_TW_CSV_EXPORT:"/temple/CSVExport",

  //NETSUITE >> EXCEPTION_REPORT
  NETSUITE_EXCEPTION_PRICEORDER: "/netsuite/orders/list_orders_price_exception",
  NETSUITE_EXCEPTION_ZERO: "/netsuite/stock/exceptions/zero_price",
  NETSUITE_EXCEPTION_LONGDESCRIPTION:
    "/netsuite/stock/exceptions/title_exception",
  NETSUITE_EXCEPTION_CATEGORY: "/netsuite/stock/exceptions/category_exception",
  NETSUITE_EXCEPTION_IMAGE: "/netsuite/stock/exceptions/image_missing",
  NETSUITE_EXCEPTION_SYNC: "/netsuite/stock/exceptions/sync_status",

  //NOT LISTED IN DRAWER MENUS
  ORDERS_ACCEPTED: "/orders/accepted_orders",
  REJECTED_ORDERS: "/orders/rejected_orders",
  CUSTOMERS_LIST: "/customers/list_customers",

  TEST: "/test",
};

export default PATHS;
